<template>
  <div>
    <div class="sign-up-splash relative">
      <div class="grid-container pb-5">
        <div class="grid-x grid-padding-x sign-up-splash-cc">
          <div class="cell small-12">
            <span><strong>Customised</strong> Clothing</span>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5">
            <h2 class="sign-up-heading">
              <span>20% Off</span><br />
              your first order
            </h2>
            <h3 class="sign-up-sub-heading">
              Sign up to redeem your code
            </h3>
            <div class="sign-up-benefits pt-1 pb-5">
              <span>Free logo set up</span><br />
              <span>Free delivery</span><br />
              <span>Delivered within 3 - 5 working days</span><br />
              <span>No express charge</span>
            </div>
          </div>
        </div>
      </div>
      <button
        @click="scroll('SignUpForm')"
        class="sign-up-trigger">
        Sign up
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUpSplash',
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
</script>
