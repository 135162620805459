<template>
  <div class="header-video-box pt-10">
    <div class="grid-container">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 relative">
          <iframe
            class="video on-top"
            src="https://www.youtube.com/embed/qkIvYzbAWQI"
            title="YouTube video player"
            frameborder="0"
            allow="
              accelerometer;
              autoplay;
              clipboard-write;
              encrypted-media;
              gyroscope;
              picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderVideo',
};
</script>
