<template>
  <div class="hide-for-print" id="pageTop">
    <div class="grid-x grid-padding-x bg-blue pt-4 pb-4">
      <div class="cell small-6 small-offset-3 text-center">
        <router-link to="/">
          <img
            width="250"
            src="../assets/cc_logo.svg"
            alt="Customised Clothing" />
        </router-link>
      </div>
      <div class="cell small-3 pt-3 text-right">
        <router-link to="/profile">
          <img
            class="header-icon"
            src="../assets/icons/person_transparent.svg"
            alt="profile" />
        </router-link>
        <router-link to="/basket">
          <span class="basket-count">{{ basketCount }}</span>
          <img
            class="header-icon"
            src="../assets/icons/shopping_cart_transparent.svg"
            alt="profile" />
        </router-link>
      </div>
    </div>
    <div v-if="isHome" class="relative bg-light-blue medium-bg-white">
      <app-sign-up-splash v-if="$store.state.signedUp === false" />
      <div :class="{ confetti: $store.state.signedUp }"></div>
      <app-sign-up-thankyou v-if="$store.state.signedUp === true" />
      <app-header-video />
    </div>
    <div v-else class="pt-4">&nbsp;</div>
  </div>
</template>

<script>
import SignUpSplash from '@/components/promotion/SignUpSplash.vue';
import SignUpThankyou from '@/components/promotion/SignUpThankyou.vue';
import HeaderVideo from '@/components/HeaderVideo.vue';

export default {
  name: 'Header',
  components: {
    appSignUpSplash: SignUpSplash,
    appSignUpThankyou: SignUpThankyou,
    appHeaderVideo: HeaderVideo,
  },
  computed: {
    basketCount() {
      return this.$store.state.basketCounter;
    },
    isHome() {
      let test = false;
      if (this.$route.name === 'Home') {
        test = true;
      }
      return test;
    },
  },
};
</script>

<style lang="scss">
.header-icon {
  width: 20px;
}
.basket-count {
  position: relative;
  top: 2px;
  display: inline-block;
  padding: 0 5px;
  color: #FFF;
  font-size: 18px;
  line-height: 0;
  font-family: 'rennerbook';
}
</style>
