<template>
  <div class="bg-blue grid-container relative pt-10">
    <button
      v-if="!$store.state.customer.id"
      @click="scroll('SignUpForm')"
      class="sign-up-trigger top">
      Sign up
    </button>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 pt-5">
        <h2 class="text-white text-center review-title">Reviews:</h2>
        <div class="review-count text-center">
          <span class="text-light-grey">5.0</span>
          &nbsp;
          <i class="star"></i>
          <i class="star"></i>
          <i class="star"></i>
          <i class="star"></i>
          <i class="star"></i>
          &nbsp;
          <span class="text-light-blue">7 Google reviews</span>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-10 pb-10">
      <div class="cell small-10 small-offset-1 review-box">
        <agile
          :slides-to-show="1"
          :nav-buttons="true"
          :speed="900"
          :center-mode="true">
          <div class="review">
            <div class="avatar">M</div>
            <div class="review-body">
              <div class="reviewer text-light-grey">
                Michael Watts
              </div>
              <div class="reviewer-count text-light-grey">
                1 review
              </div>
              <div class="review-rating">
                <i class="star"></i>
                <i class="star"></i>
                <i class="star"></i>
                <i class="star"></i>
                <i class="star"></i>
                &nbsp;
                <span class="text-light-grey">a year ago</span>
              </div>
              <div class="review-text text-white">
                I had requirements above and beyond what I would expect from an online company and
                Karan and the team delivered on time and with high quality goods. Expect to hear
                from me again soon!
              </div>
            </div>
          </div>
          <div class="review grid-x">
            <div class="cell small-1 small-offset-1 avatar">J</div>
            <div class="cell small-10 review-body">
              <div class="reviewer text-light-grey">
                John Smith
              </div>
              <div class="reviewer-count text-light-grey">
                1 review
              </div>
              <div class="review-rating">
                <i class="star"></i>
                <i class="star"></i>
                <i class="star"></i>
                <i class="star"></i>
                <i class="star"></i>
                &nbsp;
                <span class="text-light-grey">a year ago</span>
              </div>
              <div class="review-text text-white">
                The garments were received on time and better than expected. Five stars!
              </div>
            </div>
          </div>
        </agile>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';

export default {
  name: 'Reviews',
  components: {
    agile: VueAgile,
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss">
  .review-title {
    font-family: 'rennersemi';
    font-size: 32px;
    line-height: 1;
    margin-bottom: 0;
  }
  .star {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-image: url('../assets/icons/gold_star.png');
    background-size: 15px auto;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 1px;
    position: relative;
    top: 2px;
  }
  .avatar {
    background-color: #104d61;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    color: #FFF;
    line-height: 40px;
    font-family: 'rennersemi';
    font-size: 24px;
  }
  .review-body {
    float: right;
    width: 85%;
    padding: 0 10px;
  }
  .review-box .agile__nav-button.agile__nav-button--prev {
    background-image: url('../assets/icons/arrow_left_reverse.png');
  }
  .review-box .agile__nav-button.agile__nav-button--next {
    background-image: url('../assets/icons/arrow_right_reverse.png');
  }
</style>
