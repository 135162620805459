<template>
  <div id="app">
    <app-header></app-header>
    <div
      :class="background"
      class="container">
      <router-view></router-view>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Localbase from 'localbase';
import axios from './axios';

export default {
  name: 'App',
  components: {
    appHeader: Header,
    appFooter: Footer,
  },
  computed: {
    background() {
      if (this.$route.name === 'Basket') return 'bottom';
      if (this.$route.name === 'Profile') return 'domed';
      if (this.$route.name === 'ForgotPassword') return 'domed';
      if (this.$route.name === 'ResetPassword') return 'domed';
      if (this.$route.name === 'PreviousOrders') return 'domed';
      if (this.$route.name === 'UploadedArtwork') return 'domed';
      return 'top';
    },
  },
  methods: {
    checkCookies() {
      const basketId = this.$cookies.get('basketId');
      if (basketId) {
        this.$store.commit('basketId', { basketId });
        this.getBasketCount(basketId);
      } else {
        this.createBasket();
      }
      const signedUp = this.$cookies.get('signedUp');
      if (signedUp) {
        this.$store.commit('signedUp');
      }
      const customer = this.$cookies.get('customer');
      if (customer) {
        this.$store.commit('customer', customer);
      }
    },
    checkForCredentials() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.collection('users').get().then((users) => {
        if (users[0]) {
          this.$store.commit('useCredentials', users[0]);
          // test if these are still valid
          const testToken = users[0].token;
          axios.post(`/customers/testCredentials.json?token=${testToken}`)
            .then(() => {
              console.log('success');
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch('unsetCredentials');
              this.hide = false;
            });
        } else {
          this.hide = false;
        }
      });
    },
    getBasketCount(basketId) {
      axios.get(`/basketItems/getCount/${basketId}.json`)
        .then((response) => {
          const count = response.data;
          this.$store.commit('basketCounter', count);
        });
    },
    createBasket() {
      axios.get('/baskets/create.json')
        .then((response) => {
          const basketId = response.data.id;
          this.$store.commit('basketId', { basketId });
          this.$cookies.set('basketId', basketId);
        });
    },
  },
  mounted() {
    this.checkCookies();
    this.checkForCredentials();
  },
};
</script>

<style lang="scss">
// Fonts
@font-face {
  font-family: 'rennerbook_italic';
  src: url('./assets/fonts/renner__400_book_italic-webfont.woff2') format('woff2'),
    url('./assets/fonts/renner__400_book_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'rennerbook';
  src: url('./assets/fonts/renner__400_book-webfont.woff2') format('woff2'),
    url('./assets/fonts/renner__400_book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'renner_medium_italic';
  src: url('./assets/fonts/renner__500_medium_italic-webfont.woff2') format('woff2'),
   url('./assets/fonts/renner__500_medium_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'renner_medium';
  src: url('./assets/fonts/renner__500_medium-webfont.woff2') format('woff2'),
    url('./assets/fonts/renner__500_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'rennersemi_italic';
  src: url('./assets/fonts/renner__600_semi_italic-webfont.woff2') format('woff2'),
    url('./assets/fonts/renner__600_semi_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'rennersemi';
  src: url('./assets/fonts/renner__600_semi-webfont.woff2') format('woff2'),
    url('./assets/fonts/renner__600_semi-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Typography
html,body,h2,h3,h4,h5,h6,p,ul,ol,li,span {
  font-family: 'rennerbook';
}

h1 {
  font-family: 'rennersemi';
  text-transform: uppercase;
  font-size: 22px;
  color: #29287c;
  font-weight: bold;
}

h2 {
  font-family: 'rennerbook';
  font-size: 22px;
  color: #29287c;
}

h3 {
  font-family: 'rennersemi';
  text-transform: uppercase;
  font-size: 18px;
  color: #29287c;
  font-weight: bold;
}

h5 {
  font-family: 'rennersemi';
  font-size: 16px;
  color: #29287c;
}

h6 {
  font-family: 'rennerbook';
  font-size: 16px;
  color: #29287c;
}

.logo-text {
  font-size: 18px;
  text-transform: none;

  span {
    font-family: 'rennerbook';
  }
}

.free-customisation-banner {
  color: #1dd699;
  font-family: 'rennersemi';
  text-transform: uppercase;
  padding: 0 40px;
  text-align: center;
  display: inline-block;
  line-height: 1.1;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -15px;
    height: 30px;
    width: 30px;
    background-size: 30px auto;
  }
  &::before {
    left: 0;
    background-image: url('./assets/icons/green_print.svg');
  }
  &::after {
    right: 0;
    background-image: url('./assets/icons/red_embroidery.svg');
  }
}

.text-green {
  color: #1dd699;
}

.text-white {
  color: #FFF;
}

.text-light-grey {
  color: #CCC;
}

.text-light-blue {
  color: #88b3f5;
}

.text-blue {
  color: #29287c;
}

.text-small {
  font-size: 12px;
}

.text-tiny {
  font-size: 10px;
  line-height: 0;
}

.text-heavy {
  font-family: 'rennersemi';
}

.text-squashed {
  line-height: 0.9;
}

.bold {
  font-weight: bold;
}

.upload-successful {
  display: inline-block;
  color: #555;
  padding-right: 30px;
  background-image: url('./assets/icons/green_outline_tick.svg');
  background-repeat: no-repeat;
  background-position: right;
}

.strike-through {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: red;

    transform:rotate(-15deg);
  }
}

.forgot-password {
  font-family: 'rennerbook_italic';
  color: #29287c;
  display: inline-block;
  padding-top: 15px;
}

// Icons
.icon > img {
  width: 25px;
}
.icon.in-button {
  position:relative;
  top: -2px;
}

// Padding, pa = pad all, pl = pad left, pr = pad right, pt = pad tp, pb = pad bottom
.pa-1 { padding: 4px }
.pa-0 { padding: 0 }
.pa-2 { padding: 8px }
.pa-3 { padding: 12px }
.pa-4 { padding: 16px }
.pa-5 { padding: 20px }
.pa-10 { padding: 40px }
.pt-0 { padding-top: 0 }
.pt-1 { padding-top: 4px }
.pt-2 { padding-top: 8px }
.pt-3 { padding-top: 12px }
.pt-4 { padding-top: 16px }
.pt-5 { padding-top: 20px }
.pt-10 { padding-top: 40px }
.pt-15 { padding-top: 60px }
.pt-20 { padding-top: 80px }
.pb-0 { padding-bottom: 0 }
.pb-1 { padding-bottom: 4px }
.pb-2 { padding-bottom: 8px }
.pb-3 { padding-bottom: 12px }
.pb-4 { padding-bottom: 16px }
.pb-5 { padding-bottom: 20px }
.pb-10 { padding-bottom: 40px }
.pb-20 { padding-bottom: 80px }
.pb-30 { padding-bottom: 120px }
.pr-0 { padding-right: 0 }
.pr-1 { padding-right: 4px }
.pr-2 { padding-right: 8px }
.pr-3 { padding-right: 12px }
.pr-4 { padding-right: 16px }
.pr-5 { padding-right: 20px }
.pr-10 { padding-right: 40px }
.pl-0 { padding-left: 0 }
.pl-1 { padding-left: 4px }
.pl-2 { padding-left: 8px }
.pl-3 { padding-left: 12px }
.pl-4 { padding-left: 16px }
.pl-5 { padding-left: 20px }
.pl-10 { padding-left: 40px }

// Margins
.ma-0 { margin: 0!important }
.ma-1 { margin: 2px!important }
.ma-2 { margin: 4px!important }
.ma-3 { margin: 6px!important }
.ma-4 { margin: 8px!important }
.ma-5 { margin: 20px!important }
.ma-10 { margin: 40px!important }
.mb-0 { margin-bottom: 0!important }
.mb-1 { margin-bottom: 2px!important }
.mb-2 { margin-bottom: 4px!important }
.mb-3 { margin-bottom: 6px!important }
.mb-4 { margin-bottom: 8px!important }
.mb-5 { margin-bottom: 20px!important }
.mb-10 { margin-bottom: 40px!important }
.mt-0 { margin-top: 0!important }
.mt-1 { margin-top: 2px!important }
.mt-2 { margin-top: 4px!important }
.mt-3 { margin-top: 6px!important }
.mt-4 { margin-top: 8px!important }
.mt-5 { margin-top: 20px!important }
.mt-10 { margin-top: 40px!important }
.mr-0 { margin-right: 0!important }
.mr-1 { margin-right: 2px!important }
.mr-2 { margin-right: 4px!important }
.mr-3 { margin-right: 6px!important }
.mr-4 { margin-right: 8px!important }
.mr-5 { margin-right: 20px!important }
.mr-10 { margin-right: 40px!important }
.ml-0 { margin-left: 0!important }
.ml-1 { margin-left: 2px!important }
.ml-2 { margin-left: 4px!important }
.ml-3 { margin-left: 6px!important }
.ml-4 { margin-left: 8px!important }
.ml-5 { margin-left: 20px!important }
.ml-10 { margin-left: 40px!important }

.ilb {
  display: inline-block;
}

// Box shadow
.shadow {
  box-shadow: 3px 3px 5px #DDD;
}

// Border radius
.br-5 { border-radius: 5px; }

// Backgrounds
.container {
  background-color: #FFF;
  background-repeat: no-repeat;
  background-size: 150% auto;
  position: relative;
  top: -40px;

  &.top {
    background-image: url('./assets/icons/bg_top_left.svg');
    background-position: -46px -83px;
  }

  &.bottom {
    background-image: url('./assets/icons/bg_bottom_right.svg');
    background-position: bottom -60px right -80px;
  }
}

@media all and (min-width: 54rem) {
  .container.top {
    background-image: none;
  }
  .container.bottom {
    background-image: none;
  }
}

@media all and (min-width: 54rem) {
  .product-wrap {
    width: 800px;
    margin: 0 auto;
  }
}

.bg-blue {
  background-color:#29287c;
}

.bg-blue-important {
  background-color:#29287c!important;
}

.bg-light-blue {
  background-color: #d8daf1;
}

.bg-white {
  background-color: #FFF;

  &.icon-bg {
    border-radius: 50%;
  }
}

.bg-grey {
  background-color: #DBDBDB;
}

table.no-bg {
  background-color: transparent;
  border: none;
  tbody, tr, thead, td, th {
    border: none;
    background-color: transparent;
  }
}

// Buttons
.button {
  background-color: #1dd699;
  border-radius: 5px;
  color: #FFF;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'rennersemi';
  margin: 0;
  padding: 8px 10px;

  &.lower {
    text-transform: none;
  }

  &.lc {
    text-transform: none;
    font-family: 'renner_medium';
  }

  &:hover,
  &:focus {
    background-color: #29287c;
  }

  &.ghost {
    background-color: transparent;
    border: 3px solid #29287c;
    border-radius: 35px;
    color: #29287c;

    &.white {
      background-color: #FFF;
    }

    &:hover,
    &:focus {
      background-color: #29287c;
      color: #FFF;
    }
    &.active {
      border: 2px solid #1dd699;
    }
  }

  &.rounded {
    border-radius: 20px;
    height: 39px;
    line-height: 20px;
  }

  &.rounded-tall {
    border-radius: 25px;
    height: 54px;
    line-height: 12px;
  }

  &.rounded-small {
    border-radius: 15px;
    height: 26px;
    padding: 3px 10px;
    font-size: 16px;
    text-transform: none;
    line-height: 10px;
  }

  &.blue {
    background-color: #29287c;
  }

  &.small {
    font-size: 11px;
  }
}

i.link {
  cursor: pointer;
}

// Modals
.modal-outer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(150, 150, 150, 0.3);
}

.modal-uploader {
  position: fixed;
  top: 10%;
  right: 0;
  bottom: 30%;
  left: 0;
  z-index: 1;
}

.modal-inner {
  position: absolute;
  top: 1%;
  left: 4%;
  right: 4%;
  bottom: 40px;
  z-index: 3;
  background-color: #FFF;
  border: 2px solid #29287c;
  border-radius: 20px;
  padding: 40px 20px 20px 20px;
  overflow: scroll;

  &.uploader {
    bottom: auto;
    border-radius: 80px;
  }
}

@media all and (min-width: 54rem) {
  .modal-inner {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: none;
    border-radius: 0;
    padding: 40px 20% 20px 20%;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  background-image: url('./assets/icons/red_close.svg');
  background-size: 25px auto;
  background-repeat: no-repeat;
  font-size: 0;

  &.uploader {
    top: 30px;
    right: 30px;
    z-index: 1006;
  }
}

.modal-bottom {
  position: fixed;
  top: 30%;
  left: 10%;
  right: 10%;
  z-index: 3;
  background-color: #FFF;
  border: 2px solid #29287c;
  border-radius: 50px;
  text-align: center;
  padding-top: 40px;

  &.top-layer {
    z-index: 9999;
  }
}

.message {
  position: fixed;
  top: 35%;
  left: 20px;
  right: 20px;
  background-color: #FFF;
  border: 2px solid #29287c;
  border-radius: 20px;
  box-shadow: 5px 5px 5px #29287c;
  padding: 40px 30px 30px;
  font-size: 22px;
  font-family: 'rennersemi';
  color: #29287c;
  &::before {
    content: '';
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 25px auto;
    background-image: url('./assets/icons/red_close.svg');
  }
}

// Other styles
.profile {
  position: relative;
  margin-top: 50px;
  padding: 40px 20px;
  background-color: #e6e7f7;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: -25px;
    margin-left: -25px;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #29287c;
    background-image: url('./assets/icons/person_transparent.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px auto;
  }
}

 ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #29287c;
  opacity: 1; /* Firefox */
  font-family: 'rennersemi';
  font-size: 10px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #29287c;
  font-family: 'rennersemi';
  font-size: 10px;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #29287c;
  font-family: 'rennersemi';
  font-size: 10px;
}

.ghost-input {
  border-radius: 20px;
  border: 2px solid #29287c;
  font-size: 12px;

  &.no-bg {
    background-color: transparent;
  }
}
.bordered {
  border: 2px solid #29287c;
  border-radius: 15px;
  margin: 5% 5% 0 5%;
  width: 90%;
  background-color: #FFF;

  &.inner {
    margin: 0;
    width: 100%;
  }
}
.bordered-alt {
  border: 2px solid #29287c;
  border-radius: 15px;
  background-color: #FFF;
}
.bg {
  background-color: #e6e7f7;
}
.bg-dk {
  background-color:#29287c;
}
.toggle-button {
  background-color: #FFF;
  border-radius: 24px;
  color: #29287c;
  width: 100%;
  display: inline-block;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #FFF;
  box-shadow: 5px 5px 5px #DDD;

  &.small {
    padding: 3px 10px;
  }

  &.print,
  &.embroidery {
    background-size: 28px auto;
    background-position: left;
    background-repeat: no-repeat;
    padding: 0 0 0 18px;
    line-height: 30px;
    height: 32px;
  }
  &.print {
    background-image: url('./assets/icons/green_print.svg');
  }
  &.embroidery {
    background-image: url('./assets/icons/red_embroidery.svg');
  }

  &.active {
    border: 2px solid #1dd699;
    background-color: #29287c;
    color: #FFF;
  }
}
.position-selector-container {
  padding: 10px;
}
.position-selector {
  text-align: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-color: #FFF;
  background-size: 30px auto;
  background-position: center bottom 20px;
  border: 2px solid #FFF;
  border-radius: 5px;
  min-height: 50px;

  &.active {
    border: 2px solid #1dd699;
    background-color: #29287c;
    color: #FFF;

    .position-title,
    .position-sub-title {
      color: #FFF;
    }
  }

  &.pos-flc {
    background-image: url('./assets/icons/position_front_top_left.svg');
  }
  &.pos-frc {
    background-image: url('./assets/icons/position_front_top_right.svg');
  }
  &.pos-fc {
    background-image: url('./assets/icons/position_front_centre.svg');
  }
  &.pos-ls {
    background-image: url('./assets/icons/position_left_sleeve.svg');
  }
  &.pos-rs {
    background-image: url('./assets/icons/position_right_sleeve.svg');
  }
  &.pos-bn {
    background-image: url('./assets/icons/position_back_neck.svg');
  }
  &.pos-bc {
    background-image: url('./assets/icons/position_back_centre.svg');
  }
}

.position-title {
  padding-top: 2px;
  color: #29287c;
  text-transform: uppercase;
  font-size: 14px;
}
.position-sub-title {
  margin-top: 30px;
  color: #29287c;
  font-size: 12px;
}

.agile__nav-button.agile__nav-button--prev,
.agile__nav-button.agile__nav-button--next {
  position:absolute;
  top: 50%;
  margin-top: -15px;
  font-size: 0;
  width: 25px;
  height: 25px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.agile__nav-button.agile__nav-button--prev {
  left: -15px;
  background-image: url('./assets/icons/arrow_left.svg');
}
.agile__nav-button.agile__nav-button--next {
  right: -15px;
  background-image: url('./assets/icons/right_arrow.svg');
}

.icon {
  position: relative;
  top: 2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: 16px auto;
  background-repeat: no-repeat;
  background-position: center center;
  &.shopping-trolley {
    background-image: url('./assets/icons/shopping_cart_transparent.svg');
  }
}

// Inputs
input[type=text],
input[type=email],
textarea {
  border-radius: 20px;
  box-shadow: none;
  border: 2px solid #29287c;
  background-color: #e6e7f7;
}

textarea.plain {
  border-radius: 3px;
  border: none;
  background-color: #F0F0F0;
}

.text-creator.small, .decorative-text.small { font-size: 12px }
.text-creator.medium, .decorative-text.medium { font-size: 16px }
.text-creator.large, .decorative-text.large { font-size: 20px }

.decorative-text-box {
  border: 2px solid #1dd699;
  padding: 10px 20px 10px 10px;
  margin: 5px;
  border-radius: 5px;
}

select,
input[type=number] {
  border-radius: 3px;
  background-color: #e6e7f7;
}

select.small,
select.tiny {
  border-radius: 10px;
  width: 95%;
  height: 30px;
  border: 1px solid #29287c;
  color: #29287c;
  background-color: #F0F0F0;
}
select.tiny {
  height: 20px;
  line-height: 18px;
  margin-bottom: 0;
  font-family: 'rennersemi';
  font-size: 12px;
  border-radius: 5px;
  background-image: url('./assets/icons/select_arrow.png');
  background-size: 8px auto;
  background-position: center right 5px;
  padding: 0 0 0 3px;
  cursor: pointer;
}

.number-control-plus,
  .number-control-minus {
    display: inline-block;
    width: 20%;
    background-color: #DDD;
    height: 39px;
    font-size: 18px;
    cursor: pointer;

    &.inline {
      height: 30px;
      font-size: 16px;
    }

    &.border {
      border: 2px solid #29287c;
    }
  }
  .number-control-plus {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .number-control-minus {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  input[type=number].number-control-input {
    display: inline-block;
    width: 60%;
    height: 39px;
    position: relative;
    top: -1px;
    border-radius: 0;
    border: none;
    text-align: center;

    &.inline {
      height: 30px;
      top: 0;
    }

    &.no-bg {
      background-color: transparent;
    }

    &.border {
      border-top: 2px solid #29287c;
      border-bottom: 2px solid #29287c;
    }
  }

.border-bottom {
  border-bottom: 2px solid #FFF;
}

  // Signups
  .sign-up-splash {
    background-color: #d8daf1;
    padding-bottom: 20px;
  }

  @media all and (min-width: 54rem) {
    .sign-up-splash {
      background-color: #29287c;
      border-top: 4px solid #d8daf1;
      border-bottom: 4px solid #d8daf1;
    }
  }

  .sign-up-splash-cc {
    display: none;
    text-align: center;
    padding-top: 20px;

    span {
      font-family: 'rennerbook';
      color: #FFF;
      font-size: 22px;
    }
  }

  @media all and (min-width: 54rem) {
    .sign-up-splash-cc {
      display: block;
    }
  }

  .sign-up-heading {
    text-align: center;
    color: #29287c;
    font-size: 28px;
    font-family: 'rennersemi_italic';
    text-transform: uppercase;
    line-height: 1.6;
    margin-bottom: 0;
    padding-bottom: 10px;

    span {
      position: relative;
      color: #1dd699;
      font-family: 'rennersemi_italic';
      font-size: 55px;
      display: inline-block;
      padding-bottom: 1px;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        bottom: 17px;
        border-bottom: 5px solid #FFF;
      }
    }
  }

  @media all and (min-width: 54rem) {
    .sign-up-heading {
      font-size: 32px;
      color: #FFF;
    }
  }

  .sign-up-sub-heading {
    text-transform: none;
    text-align: center;
    color: #29287c;
    font-size: 24px;
    line-height: 1;
    font-family: 'rennerbook';
    padding-bottom: 10px;
  }

  @media all and (min-width: 54rem) {
    .sign-up-sub-heading {
      color: #FFF;
      font-size: 20px;
    }
  }

  .sign-up-benefits {
    text-align: center;
    span {
      display: inline-block;
      padding-left: 25px;
      padding-right: 10px;
      padding-bottom: 15px;
      background-image: url('./assets/icons/green_embroidery.svg');
      background-repeat: no-repeat;
      background-size: 18px auto;
      background-position: left top 2px;
      color: #29287c;
      font-size: 18px;
      font-family: 'rennersemi';
    }
  }

  @media all and (min-width: 54rem) {
    .sign-up-benefits {
      padding-top: 20px;
      padding-bottom: 40px;
      span {
        color: #FFF;
        font-size: 16px;
      }
    }
  }

  .sign-up-trigger {
    position: absolute;
    bottom: -30px;
    left: 50%;
    margin-left: -90px;
    height: 60px;
    width: 180px;
    text-align: center;
    line-height: 30px;
    background-color: #1dd699;
    color: #FFF;
    font-family: 'rennersemi';
    text-transform: uppercase;
    border-radius: 30px;
    border: 2px solid #1dd699;
    font-size: 24px;
    cursor: pointer;

    &.top {
      top: -25px;
      bottom: auto;
    }

    &:hover,
    &:focus {
      border: 2px solid #FFF;
    }
  }

  .sign-up-code {
    text-align: center;
    color: #29287c;
    font-family: 'rennersemi';
    font-size: 30px;
  }

  .sign-up-text {
    text-align: center;
    color: #29287c;
    font-size: 20px;
  }

  .bg-sign-up-thankyou {
    z-index: 5;
  }

  .confetti {
    position: absolute;
    top: 0;
    bottom: -20%;
    left: 0;
    right: 0;
    z-index: 2;
    background-image: url('./assets/icons/confetti.svg');
    background-repeat: no-repeat;
    background-position: -75px -65px;
    background-size: cover;
  }

  .header-video-box {
    background-color: #29287c;
  }
  @media all and (min-width: 54rem) {
    .header-video-box {
      background-color: transparent;
    }
  }

  .video {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 40px;
  }

  @media all and (min-width: 54rem) {
    .video {
      width: 60%;
      margin: 0 20%;
    }
  }

  .on-top {
    position: relative;
    z-index: 99;
  }

  // Home page
  .product-column-header {
    font-family: 'renner_medium';
    font-size: 20px;
    text-transform: uppercase;
    color: #29287c;
  }
  .product-card {
    border: 2px solid #29287c;
    border-radius: 15px;
    padding: 10px 15px;
    margin: 0 10px 15px 10px;
    box-shadow: 3px 3px 5px #DDD;
    cursor:pointer;
  }
  .product-name {
    color: #29287c;
    font-family: 'rennersemi';
    font-size: 12px;
    text-transform: uppercase;
  }
  .product-code {
    color: #29287c;
    font-size: 12px;
  }
  .price {
    color: #29287c;
    font-family: 'rennersemi';
    font-size: 10px;
  }

  .promo-not-activated {
    position: absolute;
    right: 0;
    left: 65%;
    top: -12px;
    font-size: 10px;
    color: red;
    text-align: center;
    line-height: 1.1;
    text-decoration: underline;
  }

  .blurred {
    color: transparent;
    text-shadow: 0 0 3px rgba(255, 0, 0, 0.9);
  }

  .confirmationBox {
    position: fixed;
    top: 10%;
    left: 15%;
    right: 15%;
    z-index: 3;
    background-color: #FFF;
    border: 2px solid #29287c;
    border-radius: 30px;
    padding: 10px 10px;
    text-align: center;
  }

  .confirmationBox > .close {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    right: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    background-image: url('./assets/icons/red_close.svg');
    background-size: 30px auto;
    background-repeat: no-repeat;
    font-size: 0;

    &.uploader {
      top: 30px;
      right: 30px;
      z-index: 1006;
    }
  }

  .indicator {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    background-size: 20px auto;
    background-image: url('./assets/icons/red_embroidery.svg');

    &.print {
      background-image: url('./assets/icons/green_print.svg');
    }
  }

  .basket-total-ex-vat,
  .basket-total-vat,
  .basket-total-price,
  .basket-total-vat-notice {
    display:block;
    color: #29287c;
    font-size: 11px;
    line-height: 1;
    font-family: 'rennersemi';
    text-align: right;
  }
  .basket-total-ex-vat,
  .basket-total-vat,
  .basket-total-price {
    border: 2px solid #29287c;
    padding: 5px 10px;
    border-radius: 18px;
    margin-bottom: 2px;
    margin-right: 0;
  }
  .basket-total-price {
    font-size: 22px;
  }
  .basket-total-vat-notice {
    font-size: 10px;
  }
  .artwork {
    cursor: pointer;

    &.active {
      border: 2px solid #1dd699;
    }
  }
  .warning {
    padding-bottom: 5px;
    color: #FF0000;
  }
  .close {
    position: absolute;
    top: -5px;
    right: -3px;
    cursor: pointer;
    img {
      width: 20px;
    }
  }
  .sign-up-box,
  .artwork-selector-box {
    position: relative;
    top: -20px;
    z-index: 3;
    border: 2px solid #1dd699;
    border-radius: 25px;
    background-color: white;
    padding: 10px 30px;
    text-align: center;

    .close {
      position: absolute;
      top: 50%;
      right: 10px;
      width: 26px;
      height: 26px;
      margin-top: -13px;
      font-size: 0;
      background-image: url('./assets/icons/red_close.svg');
      background-repeat: no-repeat;
    }
  }

  .customise-warning {
    position: fixed;
    top: 50%;
    left: 20%;
    right: 20%;
    margin-top: -20px;
    z-index: 9999999;
    background-color: #FFF;
    border: 3px solid #1dd699;
    border-radius: 20px;
    color: #1dd699;
    font-family: 'rennersemi';
    padding: 5px;
    text-align: center;
    text-transform: uppercase;

    .close {
      content: '';
      font-family: 'rennersemi';
      font-size: 12px;
      line-height: 16px;
      color: #FFF;
      width: 16px;
      height: 16px;
      background-color: #FF0000;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 5px;
      z-index: 2;
    }
  }

  .dummy-select {
    position: relative;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 5px;
    padding: 5px;
    display: inline-block;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -4px;
      width: 14px;
      height: 14px;
      background-image: url('./assets/icons/green_down_arrow.svg');
      background-size: 14px auto;
      background-repeat: no-repeat;
    }
  }

  .colours-list {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 250px;
    overflow: scroll;

    li {
      list-style-type: none;
      padding: 5px;
      border: 1px solid #CCC;
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid #CCC;
      }
    }
  }

  .colour-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;

    &.outlined {
      border: 1px solid #29287c;
    }

    &.move-left {
      position: relative;
      left: -5px;
    }
  }

  .size-list {
    height: 150px;
    overflow: scroll;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0 0 30px 0;

    &.expanded {
      height: auto;
      overflow: hidden;
    }

    li {
      list-style-type: none;
      padding: 5px;
      border: 1px solid #CCC;
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid #CCC;
      }
    }
  }

  @media all and (min-width: 54rem) {
    .size-list {
      height: 230px;
    }
  }

  .size-list-wrap {
    position: relative;
  }

  .size-list-more,
  .size-list-less {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255,255,255,0.9);
    height: 40px;

    &::after {
      content: '';
      position: absolute;
      right: 50%;
      top: 50%;
      margin-top: -6px;
      margin-left: -7px;
      width: 14px;
      height: 14px;
      background-image: url('./assets/icons/green_down_arrow.svg');
      background-size: 14px auto;
      background-repeat: no-repeat;
    }
  }
  .size-list-less::after {
    background-image: url('./assets/icons/green_up_arrow.png');
  }

  .number-control-input-alt {
    background: transparent!important;
    color: #1dd699;
    border: none;
    padding: 0;
    margin: 0 20px 0 0;
    height: auto;
    font-family: 'rennersemi';
    width: 40px;
    text-align: center;
    display: inline-block;
  }

  .number-control-minus-alt {
    font-family: 'rennersemi';
    margin-right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  .number-control-plus-alt {
    font-family: 'rennersemi';
    margin-right: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  @media all and (min-width: 54rem) {
    .medium-bg-white {
      background-color: #FFF;
    }
  }
  .show-for-larger {
    display: none;
  }
  @media all and (min-width: 54rem) {
    .show-for-larger {
      display: block;
    }
    .show-for-phone {
      display: none;
    }
  }
  .modal-full-page {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background: white;
    overflow: scroll;
    padding-bottom: 80px;
  }

  .garment-title,
  .garment-code,
  .garment-price {
    color: #29287c;
    font-family: 'rennersemi', arial, sans;
    text-transform: uppercase;
    font-size: 20px;
  }
  .garment-title {
    display: inline-block;
    padding-top: 10px;
  }
  .garment-code {
    font-size: 16px;
  }
  .garment-price {
    text-transform: none;
    font-size: 18px;
  }
  .toggle-colour-type {
    background-color: grey;

    img {
      position: relative;
      top: -1px;
      width: 23px;
    }
  }

  .colour-grid {
    width: 100%;
    margin: 5px 0 0 0;
    padding: 0;

    li {
      list-style-type: none;
      float: left;
      margin-right: 4px;
      cursor: pointer;
    }

    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
  input {
    font-family: 'rennersemi'!important;
  }
  .large-heading {
    font-family: 'rennerbook';
    font-size: 60px;
  }
  @media print {
    @page {
      size: auto;
      margin: 0mm;
    }

    .hide-for-print {
      display: none;
    }
  }
  body{
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }
</style>
