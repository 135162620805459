<template>
  <div class="product-wrap">
    <div class="grid-x grid-padding-x pt-15">
      <div class="cell small-12 text-center">
        <h1>Products and Prices</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-2">
      <div class="cell small-12 text-center">
        <span class="free-customisation-banner">
          Prices include<br />one free customisation
        </span>
      </div>
    </div>
    <div
      v-if="filteredGarments.length === 0"
      class="grid-x mt-5 mb-3">
      <div class="cell small-5 small-offset-1 text-center">
        <div class="product-column-header">Budget</div>
      </div>
      <div class="cell small-5 text-center">
        <div class="product-column-header">Premium</div>
      </div>
    </div>
    <div
      v-if="filteredGarments.length === 0"
      class="grid-x">
      <div class="cell small-5 small-offset-1">
        <div class="grid-x">
          <div
            v-for="(garment, index) in budgetGarments"
            :key="index"
            class="cell small-12">
            <div
              class="product-card">
              <div @click="selectGarment(garment.id)">
                <img
                  :src="`https://our-catalogue.com/RUNS/images/${garment.product_code}.jpg?width=300`"
                  alt="Garment" />
                <div class="product-name mt-4">{{ garment.short_name }}</div>
                <div class="product-code">{{ garment.product_code }}</div>
              </div>
              <div class="price relative">
                From:
                <span v-if="$store.state.signedUp === false" class="text-green text-heavy">
                  {{ garment.price | currency }}
                </span>
                <div
                  v-if="$store.state.signedUp === false"
                  @click="scroll('SignUpForm')"
                  class="promo-not-activated">
                  Get 20% off!
                </div>
                <span  v-if="$store.state.signedUp === true">
                  <span class="strike-through text-green text-heavy">
                    {{ garment.price | currency }}
                  </span>
                  <span class="text-green text-heavy">
                    {{ (garment.price - ((garment.price * 20) / 100)) | currency }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cell small-5">
        <div class="grid-x">
          <div
            v-for="(garment, index) in premiumGarments"
            :key="index"
            class="cell small-12">
            <div
              class="product-card">
              <div @click="selectGarment(garment.id)">
                <img
                  :src="`https://our-catalogue.com/RUNS/images/${garment.product_code}.jpg?width=300`"
                  alt="Garment" />
                <div class="product-name mt-4">{{ garment.short_name }}</div>
                <div class="product-code">{{ garment.product_code }}</div>
              </div>
              <div class="price relative">
                From:
                <span v-if="$store.state.signedUp === false" class="text-green text-heavy">
                  {{ garment.price | currency }}
                </span>
                <div
                  v-if="$store.state.signedUp === false"
                  @click="scroll('SignUpForm')"
                  class="promo-not-activated">
                  Get 20% off!
                </div>
                <span  v-if="$store.state.signedUp === true">
                  <span class="strike-through text-green text-heavy">
                    {{ garment.price | currency }}
                  </span>
                  <span class="text-green text-heavy">
                    {{ (garment.price - ((garment.price * 20) / 100)) | currency }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-for="(category, index) in filteredGarments"
        :key="index"
        class="cell small-6 mt-5">
        <div class="product-column-header text-center pb-3">{{ category.category }}</div>
        <div class="grid-x">
          <div
            v-for="(garment, index) in category.garments"
            :key="index"
            class="cell small-6">
            <div
              class="product-card">
              <div @click="selectGarment(garment.id)">
                <img
                  :src="`https://our-catalogue.com/RUNS/images/${garment.product_code}.jpg?width=300`"
                  alt="Garment" />
                <div class="product-name mt-4">{{ garment.short_name }}</div>
                <div class="product-code">{{ garment.product_code }}</div>
              </div>
              <div class="price relative">
                From:
                <span v-if="$store.state.signedUp === false" class="text-green text-heavy">
                  {{ garment.price | currency }}
                </span>
                <div
                  v-if="$store.state.signedUp === false"
                  @click="scroll('SignUpForm')"
                  class="promo-not-activated">
                  Get 20% off!
                </div>
                <span  v-if="$store.state.signedUp === true">
                  <span class="strike-through text-green text-heavy">
                    {{ garment.price | currency }}
                  </span>
                  <span class="text-green text-heavy">
                    {{ (garment.price - ((garment.price * 20) / 100)) | currency }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <selected-garment
      :productId="selectedProduct"
      @closeSelectedGarment="closeSelectedGarment"
      v-if="$store.state.garmentSelector" />
  </div>
</template>

<script>
import axios from '../axios';
import SelectedGarment from '../components/SelectedGarment.vue';

export default {
  name: 'Home',
  components: {
    SelectedGarment,
  },
  data() {
    return {
      budgetGarments: [],
      premiumGarments: [],
      filteredGarments: [],
      selectedProduct: 0,
    };
  },
  computed: {
    customerId() {
      return this.$store.state.customer.id;
    },
  },
  watch: {
    customerId() {
      setTimeout(() => {
        this.budgetGarments = [];
        this.premiumGarments = [];
        this.getGarments();
      }, 500);
    },
  },
  methods: {
    closeSelectedGarment() {
      this.selectedProduct = 0;
    },
    selectGarment(garment) {
      this.selectedProduct = garment;
      this.$store.commit('garmentSelector');
    },
    getGarments() {
      axios.get(`/garments/get/${this.customerId}.json`)
        .then((response) => {
          this.budgetGarments = response.data.budgetGarments;
          this.premiumGarments = response.data.premiumGarments;
          this.filteredGarments = response.data.filteredGarments;
        });
    },
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    this.getGarments();
  },
};
</script>
