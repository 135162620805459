import Vue from 'vue';
import { format } from 'date-fns';
import VueCookies from 'vue-cookies';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueScrollTo from 'vue-scrollto';
import App from './App.vue';
import store from './store';
import router from './router';

Vue.config.productionTip = false;
Vue.use(VueCookies, { expire: '7d' });
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });
Vue.use(VueScrollTo);

Vue.filter('prettyDate', (value) => {
  if (!value) return '';
  const prettyDate = format(new Date(value), 'EEEE do MMMM yyy');
  return prettyDate;
});
Vue.filter('tinyDate', (value) => {
  if (!value) return '';
  const prettyDate = format(new Date(value), 'd-MM-yy');
  return prettyDate;
});
Vue.filter('priceInPounds', (value) => {
  if (!value) return '';
  const priceInPounds = (value / 100);
  return priceInPounds;
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
