<template>
  <div>
    <div class="bg-sign-up-thankyou relative">
      <div class="grid-container pb-5">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-10">
            <h2 class="sign-up-heading">
              Thank you for signing up!
            </h2>
            <h3 class="sign-up-sub-heading pt-10">
              You're eligible for 20% off - Your discount code is:
            </h3>
            <h3 class="sign-up-code">
              CC20DSK
            </h3>
            <h3 class="sign-up-text">
              Shop discounted products below
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUpThankyou',
};
</script>
