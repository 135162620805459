<template>
  <div>
    <div class="modal-outer show-for-phone">
      <div class="modal-inner">
        <span @click="closeSelectedGarment" class="modal-close">x</span>
        <div class="grid-x grid-padding-x">
          <div class="cell small-10 small-offset-1">
            <div
              v-if="activeColour === false"
              class="text-center">
              <img
                class="single-product-image"
                v-bind:src="`https://our-catalogue.com/RUNS/images/${garment.product_code}.jpg?width=300`"
                alt="" />
            </div>
            <div
              v-else
              class="text-center"
              >
              <agile
                :slides-to-show="1"
                :nav-buttons="true"
                :speed="5"
                :center-mode="true"
                :dots="false">
                <div class="slide">
                  <img
                    class="single-product-image pad"
                    v-bind:src="`https://runsmart-dropship.s3.eu-west-2.amazonaws.com/garments/pencarrie_images/${garment.product_code}+${activeColour.code}+FRONT.jpg`"
                    alt="" /><br />
                </div>
                <div class="slide">
                  <img
                    class="single-product-image pad"
                    v-bind:src="`https://runsmart-dropship.s3.eu-west-2.amazonaws.com/garments/pencarrie_images/${garment.product_code}+${activeColour.code}+BACK.jpg`"
                    alt="" />
                </div>
              </agile>
            </div>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <p class="mb-1">Choose colour</p>
            <span @click="showColourSelect = !showColourSelect" class="dummy-select">
              <span
                v-if="garment.garment_colours[garmentColour].hex_colour.length > 6"
                style="position: relative; top: 2px;"
                >
                <span
                  :style="{
                    background: `#${garment.garment_colours[garmentColour].hex_colour.slice(0,6)}`,
                  }"
                  class="colour-dot"></span>
                <span
                  :style="{
                    background: `#${garment.garment_colours[garmentColour].hex_colour.slice(-6)}`,
                  }"
                  class="colour-dot move-left"></span>
              </span>
              <span v-else>
                <span
                  v-if="garment.garment_colours[garmentColour].hex_colour === 'FFFFFF'"
                  style="position: relative; top: 2px;"
                  :style="{ background: `#${garment.garment_colours[garmentColour].hex_colour}` }"
                  class="colour-dot outlined"></span>
                <span
                  v-else
                  style="position: relative; top: 2px;"
                  :style="{ background: `#${garment.garment_colours[garmentColour].hex_colour}` }"
                  class="colour-dot"></span>
              </span>
              {{ garment.garment_colours[garmentColour].name }}
            </span>
            <ul class="colours-list" v-if="showColourSelect">
              <li
                v-for="(color, index) in garment.garment_colours"
                :key="index"
                @click="selectColour(index)">
                <span v-if="color.hex_colour.length > 6">
                  <span
                    :style="{ background: `#${color.hex_colour.slice(0,6)}` }"
                    class="colour-dot"></span>
                  <span
                    :style="{ background: `#${color.hex_colour.slice(-6)}` }"
                    class="colour-dot move-left"></span>
                </span>
                <span v-else>
                  <span
                    v-if="color.hex_colour === 'FFFFFF'"
                    :style="{ background: `#${color.hex_colour}` }"
                    class="colour-dot outlined"></span>
                  <span
                    v-else
                    :style="{ background: `#${color.hex_colour}` }"
                    class="colour-dot"></span>
                </span>
                {{ color.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <p class="mt-1 mb-1">
              Size &amp; Quantities
            </p>
            <div class="size-list-wrap">
              <ul :class="{ expanded: expandSizeList }" class="size-list">
                <li
                  v-for="(size, sindex) in
                    garment.garment_colours[garmentColour].garment_colour_sizes"
                  :key="sindex">
                  <div class="grid-x grid-padding-x">
                    <div class="cell small-4">
                      <span
                        v-if="garment.garment_colours[garmentColour].hex_colour.length > 6"
                        style="position: relative; top: 2px;"
                        >
                        <span
                          :style="{
                            background:
                            `#${garment.garment_colours[garmentColour].hex_colour.slice(0,6)}`,
                          }"
                          class="colour-dot"></span>
                        <span
                          :style="{
                            background:
                            `#${garment.garment_colours[garmentColour].hex_colour.slice(-6)}`,
                          }"
                          class="colour-dot move-left"></span>
                      </span>
                      <span v-else>
                        <span
                          v-if="garment.garment_colours[garmentColour].hex_colour === 'FFFFFF'"
                          style="position: relative; top: 2px;"
                          :style="
                            { background: `#${garment.garment_colours[garmentColour].hex_colour}` }"
                          class="colour-dot outlined"></span>
                        <span
                          v-else
                          style="position: relative; top: 2px;"
                          :style="
                            { background: `#${garment.garment_colours[garmentColour].hex_colour}` }"
                          class="colour-dot"></span>
                      </span>
                      {{ size.size }}
                    </div>
                    <div class="cell small-8 text-right">
                      <input
                        v-model="chosenSizes[sindex].quantity"
                        class="number-control-input-alt" />
                      <button
                        class="number-control-minus-alt"
                        @click="minusSize(sindex)">-</button>
                      <button
                        class="number-control-plus-alt"
                        @click="plusSize(sindex)">+</button>
                    </div>
                  </div>
                </li>
              </ul>
              <span
                v-if="expandSizeList === false"
                @click="expandSizeList = true"
                class="size-list-more"></span>
            </div>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-6 text-center">
            <span class="garment-quantity-price">
              {{ garmentQuantity }}
              x
              <span  v-if="$store.state.signedUp === true">
                {{ (garment.price - ((garment.price * 20) / 100)) | currency }}
              </span>
              <span  v-if="$store.state.signedUp === false">
                {{ garment.price | currency }}
              </span>
            </span>
            <span class="total-price">
              <span  v-if="$store.state.signedUp === false">
                {{ garmentQuantity * garment.price | currency
                }}
              </span>
              <span  v-if="$store.state.signedUp === true">
                {{ garmentQuantity * (garment.price - ((garment.price * 20) / 100)) | currency }}
              </span>
            </span>
            <span class="vat-notice">
              ex vat
            </span>
          </div>
          <div class="cell small-6">
            <button
              v-if="addedToBasket === false"
              @click="addToBasket"
              class="button small expanded">
              Add to basket
            </button>
            <button
              v-else
              class="button small expanded"
              disabled>
              Added
            </button>
          </div>
        </div>
        <div class="grid-x grid-padding-x pt-3">
          <div class="cell small-10 small-offset-1 text-center mb-3">
            <button
              @click="closeSelectedGarment"
              class="button small ghost expanded">
              Add more garments
            </button>
          </div>
        </div>
        <div
          v-if="$store.state.basketCounter > 0"
          class="grid-x grid-padding-x mb-3">
          <div class="cell small-10 small-offset-1 text-center">
            <router-link
              to="/customisation"
              class="button small ghost expanded">
              Continue to customisation
            </router-link>
          </div>
        </div>
        <div class="grid-x grid-padding-x mb-5">
          <div class="cell small-10 small-offset-1 text-center">
            <router-link
              to="/basket"
              class="button small ghost expanded">
              Checkout
            </router-link>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-10 small-offset-1 text-center text-small">
            <div v-html="garment.descripton"></div>
            <div v-html="garment.features"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-full-page show-for-larger">
      <app-header></app-header>
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 text-center pt-5 pb-5">
            <h1>Products and prices</h1>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-4 medium-offset-2">
            <div
              v-if="activeColour === false"
              class="text-center">
              <img
                class="single-product-image"
                v-bind:src="`https://our-catalogue.com/RUNS/images/${garment.product_code}.jpg?width=300`"
                alt="" />
            </div>
            <div
              v-else
              class="text-center"
              >
              <agile
                :slides-to-show="1"
                :nav-buttons="true"
                :speed="5"
                :center-mode="true"
                :dots="false">
                <div class="slide">
                  <img
                    class="single-product-image pad"
                    v-bind:src="`https://runsmart-dropship.s3.eu-west-2.amazonaws.com/garments/pencarrie_images/${garment.product_code}+${activeColour.code}+FRONT.jpg`"
                    alt="" />
                </div>
                <div class="slide">
                  <img
                    class="slide single-product-image pad"
                    v-bind:src="`https://runsmart-dropship.s3.eu-west-2.amazonaws.com/garments/pencarrie_images/${garment.product_code}+${activeColour.code}+BACK.jpg`"
                    alt="" />
                </div>
              </agile>
            </div>
            <span class="garment-title">{{ garment.short_name }}</span><br />
            <span class="garment-code">{{ garment.product_code }}</span><br />
            <span class="garment-price">From: &pound;{{ garment.price }}</span>
            <div class="text-center bg-light-blue pa-5 mt-5 br-5">
              <div v-html="garment.description"></div>
              <!--<div v-html="garment.features"></div>-->
            </div>
          </div>
          <div class="cell small-12 medium-5">
            <p class="mb-1">
              Choose colour
              <span
                v-if="colourType === 2"
                @click="colourType = 1"
                class="toggle-colour-type">
                <img src="../assets/icons/view-grid.png" alt="grid" />
              </span>
              <span
                v-if="colourType === 1"
                @click="colourType = 2"
                class="toggle-colour-type">
                <img src="../assets/icons/view-list.png" alt="grid" />
              </span>
            </p>
            <div v-if="colourType === 1">
              <ul class="colour-grid">
                <li
                  v-for="(color, index) in garment.garment_colours"
                  :key="index"
                  @click="selectColour(index)">
                  <span
                    v-if="color.hex_colour.length > 6"
                    style="position: relative; top: 2px;"
                    >
                    <span
                      :style="{
                        background:
                        `#${color.hex_colour.slice(0,6)}`,
                      }"
                      class="colour-dot"></span>
                    <span
                      :style="{
                        background:
                        `#${color.hex_colour.slice(-6)}`,
                      }"
                      class="colour-dot move-left"></span>
                  </span>
                  <span v-else>
                    <span
                      v-if="color.hex_colour === 'FFFFFF'"
                      :title="color.name"
                      :style="{ background: `#${color.hex_colour}` }"
                      class="colour-dot outlined"></span>
                    <span
                      v-else
                      :title="color.name"
                      :style="{ background: `#${color.hex_colour}` }"
                      class="colour-dot"></span>
                  </span>
                </li>
              </ul>
            </div>
            <div v-if="colourType === 2">
              <span @click="showColourSelect = !showColourSelect" class="dummy-select">
                <span
                  v-if="garment.garment_colours[garmentColour].hex_colour.length > 6"
                  style="position: relative; top: 2px;"
                  >
                  <span
                    :style="{
                      background:
                      `#${garment.garment_colours[garmentColour].hex_colour.slice(0,6)}`,
                    }"
                    class="colour-dot"></span>
                  <span
                    :style="{
                      background:
                      `#${garment.garment_colours[garmentColour].hex_colour.slice(-6)}`,
                    }"
                    class="colour-dot move-left"></span>
                </span>
                <span v-else>
                  <span
                      v-if="garment.garment_colours[garmentColour].hex_colour === 'FFFFFF'"
                      style="position: relative; top: 2px;"
                      :style="{
                        background: `#${garment.garment_colours[garmentColour].hex_colour}` }"
                      class="colour-dot outlined"></span>
                    <span
                      v-else
                      style="position: relative; top: 2px;"
                      :style="{
                        background: `#${garment.garment_colours[garmentColour].hex_colour}` }"
                      class="colour-dot"></span>
                  </span>
                {{ garment.garment_colours[garmentColour].name }}
              </span>
              <ul class="colours-list" v-if="showColourSelect">
                <li
                  v-for="(color, index) in garment.garment_colours"
                  :key="index"
                  @click="selectColour(index)">
                  <span
                    v-if="color.hex_colour.length > 6"
                    style="position: relative; top: 2px;"
                    >
                    <span
                      :style="{
                        background:
                        `#${color.hex_colour.slice(0,6)}`,
                      }"
                      class="colour-dot"></span>
                    <span
                      :style="{
                        background:
                        `#${color.hex_colour.slice(-6)}`,
                      }"
                      class="colour-dot move-left"></span>
                  </span>
                  <span v-else>
                    <span
                      v-if="color.hex_colour === 'FFFFFF'"
                      :style="{ background: `#${color.hex_colour}` }"
                      class="colour-dot outlined"></span>
                    <span
                      v-else
                      :style="{ background: `#${color.hex_colour}` }"
                      class="colour-dot"></span>
                  </span>
                  {{ color.name }}
                </li>
              </ul>
            </div>
            <p class="mt-1 mb-1">
              Size &amp; Quantities
            </p>
            <div class="size-list-wrap">
              <ul :class="{ expanded: expandSizeList }" class="size-list">
                <li
                  v-for="(size, sindex) in
                    garment.garment_colours[garmentColour].garment_colour_sizes"
                    :key="sindex">
                  <div class="grid-x grid-padding-x">
                    <div class="cell small-4">
                      <span
                        v-if="garment.garment_colours[garmentColour].hex_colour.length > 6"
                        style="position: relative; top: 2px;"
                        >
                        <span
                          :style="{
                            background:
                            `#${garment.garment_colours[garmentColour].hex_colour.slice(0,6)}`,
                          }"
                          class="colour-dot"></span>
                        <span
                          :style="{
                            background:
                            `#${garment.garment_colours[garmentColour].hex_colour.slice(-6)}`,
                          }"
                          class="colour-dot move-left"></span>
                      </span>
                      <span v-else>
                        <span
                          v-if="garment.garment_colours[garmentColour].hex_colour === 'FFF'"
                          style="position: relative; top: 2px;"
                          :style="
                            { background: `#${garment.garment_colours[garmentColour].hex_colour}` }"
                          class="colour-dot outlined"></span>
                        <span
                          v-else
                          style="position: relative; top: 2px;"
                          :style="
                            { background: `#${garment.garment_colours[garmentColour].hex_colour}` }"
                          class="colour-dot outlined"></span>
                      </span>
                      {{ size.size }}
                    </div>
                    <div class="cell small-8 text-right">
                      <input
                        v-model="chosenSizes[sindex].quantity"
                        class="number-control-input-alt" />
                      <button
                        class="number-control-minus-alt"
                        @click="minusSize(sindex)">-</button>
                      <button
                        class="number-control-plus-alt"
                        @click="plusSize(sindex)">+</button>
                    </div>
                  </div>
                </li>
              </ul>
              <span
                v-if="expandSizeList === false"
                @click="expandSizeList = true"
                class="size-list-more"></span>
              <span
                v-if="expandSizeList === true"
                @click="expandSizeList = false"
                class="size-list-less"></span>
            </div>
            <div class="grid-x grid-padding-x">
              <div class="cell small-6 text-center">
                <span class="garment-quantity-price">
                  {{ garmentQuantity }}
                  x
                  <span  v-if="$store.state.signedUp === true">
                    {{ (garment.price - ((garment.price * 20) / 100)) | currency }}
                  </span>
                  <span  v-if="$store.state.signedUp === false">
                    {{ garment.price | currency }}
                  </span>
                </span>
                <span class="total-price">
                  <span  v-if="$store.state.signedUp === false">
                    {{ garmentQuantity * garment.price | currency
                    }}
                  </span>
                  <span  v-if="$store.state.signedUp === true">
                    {{ garmentQuantity * (
                      garment.price - ((garment.price * 20) / 100)
                      ) | currency }}
                  </span>
                </span>
                <span class="vat-notice">
                  ex vat
                </span>
              </div>
              <div class="cell small-6">
                <button
                  v-if="addedToBasket === false"
                  @click="addToBasket"
                  class="button small expanded">
                  Add to basket
                </button>
                <button
                  v-else
                  class="button small expanded"
                  disabled>
                  Added
                </button>
              </div>
            </div>
            <div class="grid-x grid-padding-x mb-4 mt-5">
              <div class="cell small-12 medium-6 medium-offset-3 text-center">
                <button
                  @click="closeSelectedGarment"
                  class="button small ghost expanded">
                  Add more garments
                </button>
              </div>
            </div>
            <div
              v-if="$store.state.basketCounter > 0"
              class="grid-x grid-padding-x mb-4">
              <div class="cell small-12 medium-6 medium-offset-3 text-center">
                <router-link
                  to="/customisation"
                  class="button small ghost expanded">
                  Continue to customisation
                </router-link>
              </div>
            </div>
            <div class="grid-x grid-padding-x mb-4">
              <div class="cell small-12 medium-6 medium-offset-3 text-center">
                <router-link
                  to="/basket"
                  class="button small ghost expanded">
                  Checkout
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';
import header from './SlimHeader.vue';
import axios from '../axios';

export default {
  name: 'SelectedGarment',
  props: [
    'productId',
  ],
  components: {
    appHeader: header,
    agile: VueAgile,
  },
  data() {
    return {
      activeColour: false,
      colourType: 1,
      expandSizeList: false,
      garment: {
        garment_colours: [
          {
            id: 0,
            garment_colour_sizes: [
              {
                id: 0,
              },
            ],
          },
        ],
      },
      garmentColour: 0,
      garmentSize: 0,
      garmentQuantity: 0,
      addedToBasket: false,
      showColourSelect: false,
      chosenSizes: [
        {
          id: 0,
          quantity: 0,
        },
      ],
    };
  },
  watch: {
    chosenSizes: {
      handler() {
        this.garmentQuantity = 0;
        for (let i = 0; this.chosenSizes.length > i; i += 1) {
          this.garmentQuantity += this.chosenSizes[i].quantity;
        }
      },
      deep: true,
    },
  },
  methods: {
    minusSize(index) {
      this.chosenSizes[index].quantity = parseInt(this.chosenSizes[index].quantity, 10);
      this.chosenSizes[index].quantity -= 1;
      const tempSizes = [];
      for (let i = 0; this.chosenSizes.length > i; i += 1) {
        tempSizes.push(this.chosenSizes[i]);
      }
      this.chosenSizes = tempSizes;
    },
    plusSize(index) {
      this.chosenSizes[index].quantity = parseInt(this.chosenSizes[index].quantity, 10);
      this.chosenSizes[index].quantity += 1;
      console.log(this.chosenSizes[index].quantity);
      const tempSizes = [];
      for (let i = 0; this.chosenSizes.length > i; i += 1) {
        tempSizes.push(this.chosenSizes[i]);
      }
      this.chosenSizes = tempSizes;
    },
    selectColour(index) {
      this.activeColour = this.garment.garment_colours[index];
      this.chosenSizes = this.activeColour.garment_colour_sizes;
      for (let i = 0; this.activeColour.garment_colour_sizes.length > i; i += 1) {
        this.chosenSizes[i].quantity = 0;
      }
      this.garmentColour = index;
      this.showColourSelect = false;
    },
    addToBasket() {
      let price = 0;
      if (this.$store.state.signedUp) {
        price = (
          this.garment.price - ((this.garment.price * 20) / 100)
        );
      } else {
        price = this.garment.price;
      }
      const postData = {};
      postData.basketId = this.$store.state.basketId;
      postData.itemType = 1;
      postData.price = price;
      postData.itemIds = [];
      for (let i = 0; this.chosenSizes.length > i; i += 1) {
        const size = {};
        size.id = this.chosenSizes[i].id;
        size.quantity = this.chosenSizes[i].quantity;
        postData.itemIds.push(size);
      }
      postData.quantity = this.garmentQuantity;
      axios.post('/basketItems/add.json', postData)
        .then((response) => {
          this.addedToBasket = true;
          this.$store.commit('basketCounter', response.data);
          setTimeout(() => {
            this.addedToBasket = false;
          }, 1000);
        });
    },
    closeSelectedGarment() {
      this.$store.commit('noGarmentSelector');
      this.$emit('closeSelectedGarment');
    },
    getGarment() {
      axios.get(`/garments/getSingle/${this.productId}.json`)
        .then((response) => {
          this.garment = response.data;
          this.chosenSizes = response.data.garment_colours[0].garment_colour_sizes;
          for (let i = 0; this.chosenSizes.length > i; i += 1) {
            this.chosenSizes[i].quantity = 0;
          }
        });
    },
  },
  mounted() {
    this.getGarment();
  },
};
</script>

<style lang="scss">
  .garment-quantity-price {
    color: #29287c;
    font-size: 12px;
    line-height: 1;
    display: block;
  }
  .total-price {
    color: #29287c;
    font-size: 18px;
    line-height: 1;
    font-family: 'rennersemi';
    line-height: 1;
    display: block;
  }
  .vat-notice {
    color: #29287c;
    font-size: 12px;
    line-height: 1;
    font-family: 'rennersemi';
    display: block;
    text-transform: uppercase;
  }
</style>
