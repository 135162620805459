<template>
  <div id="pageTop">
    <div class="grid-x grid-padding-x bg-blue pt-4 pb-4">
      <div class="cell small-6 small-offset-3 text-center">
        <router-link to="/">
          <img src="../assets/logo.png" alt="Customised Clothing" />
        </router-link>
      </div>
      <div class="cell small-3 pt-3 text-right">
        <router-link to="/profile">
          <img
            class="header-icon"
            src="../assets/icons/person_transparent.svg"
            alt="profile" />
        </router-link>
        <router-link to="/basket">
          <span class="basket-count">{{ basketCount }}</span>
          <img
            class="header-icon"
            src="../assets/icons/shopping_cart_transparent.svg"
            alt="profile" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlimHeader',
  computed: {
    basketCount() {
      return this.$store.state.basketCounter;
    },
  },
};
</script>

<style lang="scss">
.header-icon {
  width: 20px;
}
.basket-count {
  position: relative;
  top: 2px;
  display: inline-block;
  padding: 0 5px;
  color: #FFF;
  font-size: 18px;
  line-height: 0;
  font-family: 'rennerbook';
}
</style>
