<template>
  <div class="hide-for-print">
    <app-process v-if="showProcess" />
    <app-reviews v-if="showProcess" />
    <div v-if="showProcess">
      <app-sign-up-form v-if="$store.state.signedUp === false" />
    </div>
    <div v-if="customiseWarning === true" class="customise-warning">
      Add garments first
      <span class="close" @click="customiseWarning = false">X</span>
    </div>
    <div
      v-if="isCheckout === false && $store.state.garmentSelector === false && isCustomise === false"
      class="grid-x footer">
      <div class="cell small-6 pl-5 pt-1">
        <div class="grid-x grid-padding-x pt-3">
          <div class="col small-4 text-right pr-4">
            <img width="20px" src="../assets/icons/stopwatch_icon.png" alt="" />
          </div>
          <div class="col small-8">
            <span class="footer-button-timer">
              {{ timeLeft }} Left<br /> - Free delivery
            </span>
          </div>
        </div>
      </div>
      <div class="cell small-6 relative">
        <div class="footer-button customise-footer-button">
          <router-link
            v-if="this.$store.state.basketCounter"
            class="text-white" to="/customisation">
              <span class="footer-button-title">Customise</span>
              <span class="footer-button-text">
                Add garments<br />to basket first
              </span>
          </router-link>
          <div @click="customiseWarning = true" v-else>
            <span class="footer-button-title">Customise</span>
            <span class="footer-button-text">
              Add garments<br />to basket first
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isCheckout === false && $store.state.garmentSelector === true && isCustomise === false"
      class="grid-x footer">
      <div class="cell small-4 pl-5 pt-1">
        <div
          @click="showAllGarments"
          class="grid-x grid-padding-x link">
          <div class="col small-2 pt-1">
            <img width="10px" src="../assets/icons/arrow_left.svg" alt="" />
          </div>
          <div class="col small-2 pt-1">
            <img width="18px" src="../assets/icons/outline_tshirt_blue.png" alt="" />
          </div>
          <div class="col small-8 pt-2">
            <span class="small-back-text">Back to garments</span>
          </div>
        </div>
      </div>
      <div class="cell small-4 pl-5 pt-1 bg-blue">
        <div class="grid-x grid-padding-x pt-1">
          <div class="col small-4 text-right pr-4">
            <img width="20px" src="../assets/icons/stopwatch.svg" alt="" />
          </div>
          <div class="col small-8">
            <span class="footer-button-timer text-white">
              {{ timeLeft }} Left - Free delivery
            </span>
          </div>
        </div>
      </div>
      <div class="cell small-4 relative">
        <div class="footer-button customise-footer-button">
          <router-link
            v-if="this.$store.state.basketCounter"
            class="text-white" to="/customisation">
              <span class="footer-button-title">Customise</span>
              <span class="footer-button-text">
                Add garments<br />to basket first
              </span>
          </router-link>
          <div @click="customiseWarning = true" v-else>
            <span class="footer-button-title">Customise</span>
            <span class="footer-button-text">
              Add garments<br />to basket first
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isCustomise === true"
      class="grid-x footer">
      <div class="cell small-4 pl-5 pt-1">
        <div
          @click="showAllGarments"
          class="grid-x grid-padding-x link">
          <div class="col small-2 pt-1">
            <img width="10px" src="../assets/icons/arrow_left.svg" alt="" />
          </div>
          <div class="col small-2 pt-1">
            <img width="18px" src="../assets/icons/outline_tshirt_blue.png" alt="" />
          </div>
          <div class="col small-8 pt-2">
            <span class="small-back-text">Back to garments</span>
          </div>
        </div>
      </div>
      <div class="cell small-4 pl-5 pt-1 bg-blue">
        <div class="grid-x grid-padding-x pt-1">
          <div class="col small-4 text-right pr-4">
            <img width="20px" src="../assets/icons/stopwatch.svg" alt="" />
          </div>
          <div class="col small-8">
            <span class="footer-button-timer text-white">
              {{ timeLeft }} Left - Free delivery
            </span>
          </div>
        </div>
      </div>
      <div class="cell small-4 relative">
        <div class="footer-button pt-2">
          <router-link to="/basket">
            <img
              class="mr-2"
              width="20px"
              src="../assets/icons/shopping_cart_transparent.svg"
              alt="" />
            <span class="footer-button-title text-white">Checkout</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Process from '@/components/Process.vue';
import Reviews from '@/components/Reviews.vue';
import SignUpForm from '@/components/promotion/SignUpForm.vue';

export default {
  name: 'Footer',
  components: {
    appProcess: Process,
    appReviews: Reviews,
    appSignUpForm: SignUpForm,
  },
  data() {
    return {
      timeLeft: '',
      customiseWarning: false,
    };
  },
  computed: {
    showProcess() {
      let show = true;
      if (this.$route.name === 'Profile') show = false;
      if (this.$route.name === 'ForgotPassword') show = false;
      if (this.$route.name === 'ResetPassword') show = false;
      if (this.$route.name === 'PreviousOrders') show = false;
      if (this.$route.name === 'UploadedArtwork') show = false;
      if (this.$route.name === 'Basket') show = false;
      if (this.$route.name === 'Checkout') show = false;
      if (this.$route.name === 'Payment') show = false;
      if (this.$route.name === 'Thankyou') show = false;
      if (this.$route.name === 'EditCustomisation') show = false;
      return show;
    },
    isCheckout() {
      let isCheckout = false;
      if (this.$route.name === 'Checkout') isCheckout = true;
      if (this.$route.name === 'Payment') isCheckout = true;
      if (this.$route.name === 'Thankyou') isCheckout = true;
      if (this.$route.name === 'Basket') isCheckout = true;
      return isCheckout;
    },
    isCustomise() {
      let isCustomise = false;
      if (this.$route.name === 'Customisation') isCustomise = true;
      return isCustomise;
    },
  },
  methods: {
    showAllGarments() {
      this.$store.commit('noGarmentSelector');
      if (this.$route.name !== 'Home') {
        this.$router.push('/');
      }
    },
    getTimeLeft() {
      const start = new Date();
      start.setHours(16, 0, 0); // 4pm
      const now = new Date();
      if (now > start) { // too late, go to tomorrow
        start.setDate(start.getDate() + 1);
      }
      const remain = ((start - now) / 1000);
      const hours = Math.floor(remain / 3600);
      const minutes = Math.floor((remain - (hours * 3600)) / 60);
      const timeLeft = `${hours}Hr ${minutes}Mn`;
      this.timeLeft = timeLeft;
    },
  },
  mounted() {
    this.getTimeLeft();
    this.timer = window.setInterval(() => {
      this.getTimeLeft();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
  .relative {
    position:relative;
  }
  .footer-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #1dd699;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-transform: uppercase;
    color: #FFF;
    font-family: 'rennersemi';
    text-align: center;
  }
  .footer-button-title {
    display: inline-block;
    padding-top: 10px;
    font-family: 'rennersemi';
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1;
  }
  .footer-button-text {
    display: block;
    padding-top: 2px;
    font-family: 'rennersemi';
    text-transform: uppercase;
    font-size: 8px;
    line-height: 1;
  }
  .footer-button-timer {
    display: block;
    font-family: 'rennersemi';
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1;
    color: #29287c;
    padding-top: 2px;
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: #FFF;
    min-height: 55px;
    box-shadow: 3px -3px 5px rgba(0,0,0,0.2);
  }
  .footer-price-title {
    font-family: 'rennersemi';
    font-size: 14px;
    text-transform: uppercase;
    display: block;
    color: #29287c;
    line-height: 0.2;
    padding-top: 15px;
  }
  .footer-price {
    font-family: 'rennersemi';
    font-size: 22px;
    text-transform: uppercase;
    margin-right: 1px;
    color: #29287c;
  }
  .footer-vat-notice {
    font-family: 'rennerbook';
    font-size: 12px;
    color: #29287c;
  }
  .small-back-text {
    display: inline-block;
    font-family: 'rennersemi';
    font-size: 10px;
    color: #29287c;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
  }
  .link {
    cursor: pointer;
  }
  .customise-footer-button {
    padding-left: 20px;
    background-image: url('../assets/icons/customise_footer.png');
    background-position: center left 10px;
    background-repeat: no-repeat;
    background-size: 18px auto;
    span a:hover {
      color: white;
    }
  }
</style>
