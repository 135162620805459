import Vue from 'vue';
import Vuex from 'vuex';
import Localbase from 'localbase';

const db = new Localbase('db');
db.config.debug = false;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    userid: '',
    username: '',
    basketId: 0,
    basketCounter: 0,
    images: [],
    customer: {
      id: 0,
      password_set: 0,
    },
    order: {},
    signedUp: false,
    garmentSelector: false,
    profileDestination: '',
  },
  mutations: {
    passwordSet(state) {
      state.customer.password_set = true;
    },
    garmentSelector(state) {
      state.garmentSelector = true;
    },
    garmentDeSelector(state) {
      state.garmentSelector = false;
    },
    noGarmentSelector(state) {
      state.garmentSelector = false;
    },
    setCredentials(state, credentials) {
      state.token = credentials.json.token;
      state.userid = credentials.user;
      state.username = credentials.name;
    },
    unsetCredentials(state) {
      state.token = '';
      state.userid = '';
      state.username = '';
    },
    useCredentials(state, credentials) {
      state.token = credentials.token;
      state.userid = credentials.id;
      state.username = credentials.name;
    },
    basketId(state, value) {
      state.basketId = value.basketId;
    },
    clearImages(state) {
      state.images = [];
    },
    addImages(state, imageData) {
      state.images = state.images.concat(imageData);
    },
    customer(state, data) {
      state.customer = data;
    },
    order(state, data) {
      state.order = data;
    },
    signedUp(state) {
      state.signedUp = true;
    },
    basketCounter(state, quantity) {
      state.basketCounter += quantity;
    },
    basketTotal(state, quantity) {
      state.basketCounter = quantity;
    },
    setProfileDestination(state, destination) {
      state.profileDestination += destination;
    },
  },
  actions: {
    setCredentials({ commit }, credentials) {
      commit('setCredentials', credentials);
      db.collection('users').doc({ id: credentials.user }).get().then((document) => {
        if (document) {
          db.collection('users').doc({ id: document.id }).set({
            id: credentials.user,
            name: credentials.name,
            token: credentials.json.token,
          });
        } else {
          db.collection('users').add({
            id: credentials.user,
            name: credentials.name,
            token: credentials.json.token,
          });
        }
      });
    },
    unsetCredentials({ commit }) {
      commit('unsetCredentials');
      db.collection('users').delete();
    },
  },
  getters: {},
});
