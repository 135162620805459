<template>
  <div class="bg-light-blue grid-container pt-5 pb-10">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <h2 class="process-header">Our process</h2>
      </div>
    </div>
    <div class="grid-x">
      <div class="cell small-6">
        <div class="process-card">
          <img src="../assets/icons/process_hangar.svg" alt="" /><br /><br />
          Garment Selection
        </div>
      </div>
      <div class="cell small-6">
        <div class="process-card">
          <img src="../assets/icons/process_order.svg" alt="" /><br /><br />
          Order and Visual Proof Approval
        </div>
      </div>
    </div>
    <div class="grid-x">
      <div class="cell small-6">
        <div class="process-card">
          <img src="../assets/icons/process_swatch.svg" alt="" /><br /><br />
          Embroidered Swatch Approval
        </div>
      </div>
      <div class="cell small-6">
        <div class="process-card">
          <img src="../assets/icons/process_delivery.svg" alt="" /><br /><br />
          Your Order Delivered Within 3-5 Working Days Of Approval/Payment
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Process',
};
</script>

<style lang="scss">
  .process-header {
    color: #29287c;
    font-family: 'rennersemi';
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
  }

  .process-card {
    padding: 15px 0 0 0;
    background-color: #FFF;
    text-align: center;
    border-radius: 5px;
    font-size: 10px;
    margin: 4px;
    min-height: 140px;

    img {
      width: 40%;
    }
  }
</style>
